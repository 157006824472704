.redefinir-senha-container {
    margin: 15% auto;
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra suave */
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="password"] {
    width: calc(100% - 40px); /* 100% da largura do container - 40px para o padding */
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease; /* Adiciona uma transição suave */
}

input[type="password"]:focus {
    border-color: #003D42; /* Muda a cor da borda ao focar */
}

button {
    width: 100%;
    padding: 12px;
    background-color: #003D42;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Adiciona uma transição suave */
}

button:hover {
    background-color: #003D42; /* Muda a cor de fundo ao passar o mouse */
}
